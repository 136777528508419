<template>
  <article class="artilces-list">
    <div v-for="item in docs" :key="`article-${item._id}`" class="item">
      <div class="poster">
        <img :data-src="item.poster" src="/static_files/no-poster.png" :alt="item.title" class="lazyload">
      </div>
      <div class="body">
        <ul v-if="item.tags.length" class="tags">
          <li v-for="tag in item.tags" :key="`article-tag-${tag.id}}`">
            <nuxt-link :to="`/tag/${tag.id}-${tag.name}`">
              <span>#</span>{{ tag.name }}
            </nuxt-link>
          </li>
        </ul>
        <h2>
          {{ item.title }}
        </h2>
        <p class="description">
          {{ item.short }}
        </p>
      </div>
      <nuxt-link :to="`/article/${item.pretty}`" class="readlink">
        {{ item.title }}
      </nuxt-link>
    </div>
  </article>
</template>

<script>
export default {
  props: { docs: { type: Array, require: true, default: () => ([]) } }
}
</script>

<style src="~/assets/artilces-list.styl" lang="stylus" />
